const Header = ({ data }) => {
    const handleDate = (startDateP, endDateP) => {
        const startDateString = startDateP;
        const endDateString = endDateP;

        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        const options = { year: "numeric", month: "long", day: "numeric" };

        const formattedStartDate = startDate.toLocaleDateString(
            "en-US",
            options
        );
        const formattedEndDate = endDate.toLocaleDateString("en-US", options);

        const startDay = formattedStartDate.split(" ")[1];
        const startMonth = formattedStartDate.split(" ")[0];
        const endDay = formattedEndDate.split(" ")[1];
        const endMonth = formattedEndDate.split(" ")[0];

        if (startMonth == endMonth) {
            const output = `${startDay.slice(0, -1)} -  ${endDay.slice(
                0,
                -1
            )} ${endMonth}  `;
            return output;
        } else {
            const output = `${startDay.slice(
                0,
                -1
            )} ${startMonth}  - ${endDay.slice(0, -1)}  ${endMonth}   `;
            return output;
        }

        return "";
    };
    console.log(data, "data33333333333333333333333333333333");
    return (
        <header className="logo">
            {data != null ? (
                <div>
                    {data?.id != 244 && data?.logo && (
                        <img
                            src={`https://frontiers.o-projects.org/storage/${data?.logo}`}
                            style={{
                                width: "100%",
                            }}
                        />
                    )}
                    <h1
                        className=""
                        style={{ color: data?.id == 244 ? "black" : "white" }}
                    >
                        {data?.name}
                    </h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: data?.description }}
                        style={{ color: data?.id == 244 ? "black" : "white" }}
                    ></div>
                    <h2 style={{ color: data?.id == 244 ? "black" : "white" }}>
                        {/* 9 - 11 OCTOBER */}
                        {handleDate(data?.startdate, data?.enddate)}
                    </h2>
                    <span
                        style={{ color: data?.id == 244 ? "black" : "white" }}
                    >
                        {data?.location}
                    </span>
                </div>
            ) : (
                <div class="lds-dual-ring"></div>
            )}
        </header>
    );
};

export default Header;
