import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "./form.css";
import Checkboxes from "./Checkboxes";
import DynamicQuestions from "./DynamicQuestions";
import Header from "./Header";
import imageToBase64 from "../utils/helperFunction";
import Radiobutton from "./Radiobutton";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Form = () => {
    const [language, setlanguage] = useState("");
    const [data, setdata] = useState(null);
    console.log(data, "data");
    const [ticketTypes, setticketTypes] = useState(null);
    const [checkBoxValidation, setcheckBoxValidation] = useState(false);
    const [recordTicketsTypes, setRecordTicketsTypes] = useState([]);
    useEffect(() => {
        axios
            .get(
                `https://frontiers.o-projects.org/api/website/event/metadata?alias=${currentPath.slice(
                    1
                )}`
            )
            .then((data) => {
                setdata(data.data.record);
                setticketTypes(data.data.ticketTypes);
                setRecordTicketsTypes(data.data.record.tickettypes);
                setQuestions(data.data.record.questions);
            })
            .catch(() => {
                setnotFound(true);
            });
    }, []);
    useEffect(() => {
        const lang = localStorage.getItem("language");
        setlanguage(lang ? lang : "En");
    }, []);

    const currentPath = window.location.pathname;

    const [user, setUser] = useState({
        name: "",
        lname: "",
        mobileno: "",
        email: "",
        event_id: "",
        ticketid: "",
        tickets: [
            {
                ticketid: 314,
                quantity: 1,
            },
        ],
        quantity: 1,
        payment_method: "online",
        walletnumber: "",
        guests: [],
        answers: [],
    });
    useEffect(() => {
        ticketTypes &&
            setUser({
                name: "",
                lname: "",
                mobileno: "",
                email: "",
                event_id: data?.id,
                ticketid: ticketTypes[0]?.id,
                tickets: [
                    {
                        ticketid: ticketTypes[0]?.id,
                        quantity: 1,
                    },
                ],
                quantity: 1,
                payment_method: "online",
                walletnumber: "",
                guests: [],
                answers: [],
            });
    }, [ticketTypes, data]);

    const [questions, setQuestions] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;
    const SECRET_KEY = process.env.REACT_APP_reCAPTCHA_SECRET_KEY;
    const captchaRef = useRef(null);

    const [error, setError] = useState({});

    const [isError, setIsError] = useState(false);

    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        function isObjectEmpty(obj) {
            if (Object.keys(obj).length === 0 && obj.constructor === Object) {
                return true;
            }

            for (const key in obj) {
                if (obj[key] !== false) {
                    return false;
                }
            }

            return true;
        }

        setIsError(isObjectEmpty(user));
        setIsError(isObjectEmpty(error));
    }, [error, user]);

    const handleChange = (e, itemId) => {
        const { name, value } = e.target;

        switch (name) {
            case "name":
                setUser((prevUser) => ({
                    ...prevUser,
                    name: value,
                }));
                break;
            case "lname":
                setUser((prevUser) => ({
                    ...prevUser,
                    lname: value,
                }));
                break;
            case "email":
                setUser((prevUser) => ({
                    ...prevUser,
                    email: value,
                }));
                break;
            case "mobileno":
                setUser((prevUser) => ({
                    ...prevUser,
                    mobileno: value,
                }));
                break;
            case "Company Name":
                setUser((prevUser) => ({
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        { questionid: itemId, answer: value },
                    ],
                }));
                break;
            case "Job Title":
                setUser((prevUser) => ({
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        { questionid: 131, answer: value },
                    ],
                }));
                break;
            case "Country":
                setUser((prevUser) => ({
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        { questionid: 132, answer: value },
                    ],
                }));
                break;
            default:
                return user;
        }
    };

    const handleChanger = (e, itemId) => {
        const { name, value } = e.target;
        setUser((prevUser) => {
            const answerExists = prevUser.answers.some(
                (answer) => answer.questionid === itemId
            );

            if (answerExists) {
                const newAnswers = prevUser.answers.map((answer) => {
                    if (answer.questionid === itemId) {
                        return { ...answer, answer: value };
                    }
                    return answer;
                });

                return { ...prevUser, answers: newAnswers };
            } else {
                return {
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        { questionid: itemId, answer: value },
                    ],
                };
            }
        });
    };
    const handleSelector = (e, itemId) => {
        const { label } = e;
        setUser((prevUser) => {
            const answerExists = prevUser.answers.some(
                (answer) => answer.questionid === itemId
            );

            if (answerExists) {
                const newAnswers = prevUser.answers.map((answer) => {
                    if (answer.questionid === itemId) {
                        return { ...answer, answer: label };
                    }
                    return answer;
                });

                return { ...prevUser, answers: newAnswers };
            } else {
                return {
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        { questionid: itemId, answer: label },
                    ],
                };
            }
        });
    };
    const handleInputFile = (base64Data, itemId) => {
        // const { name, value } = e.target;
        setUser((prevUser) => {
            const answerExists = prevUser.answers.some(
                (answer) => answer.questionid === itemId
            );

            if (answerExists) {
                const newAnswers = prevUser.answers.map((answer) => {
                    if (answer.questionid === itemId) {
                        return {
                            ...answer,
                            answer: base64Data,
                        };
                    }
                    return answer;
                });

                return { ...prevUser, answers: newAnswers };
            } else {
                return {
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        {
                            questionid: itemId,
                            answer: base64Data,
                        },
                    ],
                };
            }
        });
    };
    const handleRadiobuttonChange = (e, itemId) => {
        const { name, value } = e.target;
        setUser((prevUser) => {
            const answerExists = prevUser.answers.some(
                (answer) => answer.questionid === itemId
            );

            if (answerExists) {
                const newAnswers = prevUser.answers.map((answer) => {
                    if (answer.questionid === itemId) {
                        return {
                            ...answer,
                            answer: Number(value),
                        };
                    }
                    return answer;
                });

                return { ...prevUser, answers: newAnswers };
            } else {
                return {
                    ...prevUser,
                    answers: [
                        ...prevUser.answers,
                        {
                            questionid: itemId,
                            answer: Number(value),
                        },
                    ],
                };
            }
        });
    };

    const handleCheckboxChange = (e, questionid, optionId) => {
        const { checked, value } = e.target;
        setCheckedCheckboxes((prevState) => {
            if (checked) {
                // return state with updated value
                return [
                    ...prevState,
                    { questionId: questionid, optionId: value },
                ];
            } else {
                return prevState.filter((item) => item?.optionId !== value);
            }
        });
        console.log(checkedCheckboxes, "prevState");
    };

    useEffect(() => {
        console.log(checkedCheckboxes, "useeffect");
        const result = checkedCheckboxes.reduce((acc, current) => {
            const questionid = current.questionId;
            const value = parseInt(current.optionId);
            console.log(acc[questionid], "useeffect");

            if (acc[questionid]) {
                acc[questionid].answer.push(value);
            } else {
                acc[questionid] = { questionid, answer: [value] };
            }

            return acc;
        }, {});
        console.log(result, "useeffect");

        const finalResult = Object.values(result);

        var updatedAnswers = [...user.answers];
        console.log(finalResult, updatedAnswers, "final");

        console.log(updatedAnswers, "hi3");
        for (let i = 0; i < updatedAnswers.length; i++) {
            let findIndexOfQuestion1 = data?.questions?.findIndex(
                (question) => question.id === updatedAnswers[i].questionid
            );
            // console.log(
            //     findIndexOfQuestion1,
            //     data.questions[findIndexOfQuestion1],
            //     findIndexOfQuestion1 &&
            //         (data.questions[findIndexOfQuestion1]?.type == "checkbox" ||
            //             data.questions[findIndexOfQuestion1]?.type ==
            //                 "session"),
            //     "hi1"
            // );
            if (
                findIndexOfQuestion1 &&
                (data.questions[findIndexOfQuestion1]?.type == "checkbox" ||
                    data.questions[findIndexOfQuestion1]?.type == "session")
            ) {
                let findIndexOfQuestion3 = finalResult.findIndex(
                    (answer) => answer.questionid === findIndexOfQuestion1
                );
                console.log(findIndexOfQuestion3, finalResult, i, "hi3");
                if (findIndexOfQuestion3 == -1) {
                    updatedAnswers.splice(i, 1);
                }
            }
        }

        if (finalResult.length !== 0) {
            console.log(updatedAnswers, "updatedAnswers");
            for (let i = 0; i < finalResult.length; i++) {
                var existingAnswerIndex = updatedAnswers.findIndex(
                    (answer) => answer.questionid === finalResult[i].questionid
                );
                console.log(
                    existingAnswerIndex,
                    finalResult[i].questionid,
                    "hi3"
                );

                if (existingAnswerIndex != -1) {
                    const findIndexOfQuestion = data?.questions?.findIndex(
                        (question) => question.id === finalResult[i].questionid
                    );
                    //   console.log("hi2");

                    // if (data?.questions[findIndexOfQuestion]?.maxchoice) {
                    //    finalResult[i].answer.length >
                    //    data?.questions[findIndexOfQuestion]?.maxchoice
                    //      ? setcheckBoxValidation(true)
                    //      : setcheckBoxValidation(false);
                    //  }
                    //     if (data?.questions[findIndexOfQuestion]?.mandatory === 1) {
                    //       finalResult[0].answer.length === 0 && setcheckBoxValidation(true);

                    //     setIsChecked(true);
                    //     // Update the existing

                    updatedAnswers[existingAnswerIndex].answer =
                        finalResult[i].answer;
                } else {
                    //     // Add a new answer
                    console.log("hi2");
                    updatedAnswers.push(finalResult[i]);
                }
            }
            console.log(updatedAnswers, "updatedAnswers");

            setUser({
                ...user,
                answers: updatedAnswers,
            });
        }
    }, [checkedCheckboxes]);
    const test = (value) => {
        setUser((prevUser) => ({
            ...prevUser,
            answers: [...prevUser.answers, value],
        }));
    };

    const handleBlur = (e) => {
        if (!e.target.value) {
            setError((prevError) => ({
                ...prevError,
                [e.target.name]: true,
            }));
        } else {
            setError((prevError) => ({
                ...prevError,
                [e.target.name]: false,
            }));
        }
    };

    const handleFocus = (e) => {
        if (!e.target.value <= 0) {
            setError((prevError) => ({
                ...prevError,
                [e.target.name]: false,
            }));
        }
    };

    const onSubmit = async () => {
        console.log(data);
        // const token = captchaRef.current.getValue();
        if (user.answers.length === 0 && questions?.length > 0) {
            setcheckBoxValidation(true);
            console.log("Please answer all the questions");
            console.log(isError);
            return;
        } else {
            setcheckBoxValidation(false);
        }
        console.log(checkBoxValidation);
        if (!checkBoxValidation) {
            setIsSubmitting(true);
            axios({
                url: "https://frontiers.o-projects.org/api/event/order/create/guest",
                method: "POST",
                data: user,
            })
                .then((res) => {
                    window.location.replace(res.data.paymentLink);
                    setIsSubmitting(false);
                    captchaRef.current.reset();
                })
                .catch((err) => {
                    setIsSubmitting(false);
                    console.log(err);
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    console.log("user => ", user);

    // Check if at least one checkbox is checked
    // useEffect(() => {
    //   if (checkedCheckboxes.length > 0) {
    //     setIsChecked(true);
    //   } else {
    //     setIsChecked(false);
    //   }
    // }, [checkedCheckboxes]);

    const [notFound, setnotFound] = useState(false);

    return (
        <>
            {notFound ? (
                <p
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        height: "100vh",
                        backgroundColor: "black",
                    }}
                >
                    Page not found
                </p>
            ) : data != null ? (
                <div
                    className="App"
                    style={{
                        backgroundColor: data?.id == 244 ? "white" : "black",
                    }}
                >
                    <div
                        className="reg_form"
                        style={{
                            direction: language === "Ar" ? "rtl" : "ltr",
                            position: "relative",
                        }}
                    >
                        {data?.supports_arabic === 1 && (
                            <p
                                style={{
                                    color: "black",
                                    position: "absolute",
                                    backgroundColor: "white",
                                    borderRadius: "2px",
                                    padding: "8px 15px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    right: "0%",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    localStorage.setItem(
                                        "language",
                                        language === "En" ? "Ar" : "En"
                                    );
                                    setlanguage((prev) => {
                                        return prev === "En" ? "Ar" : "En";
                                    });
                                }}
                            >
                                {/* <img
                                    src={
                                        language === "En"
                                            ? "/egypt.svg"
                                            : "/america.svg"
                                    }
                                    width={25}
                                    // style={{ borderRadius: "100%" }}
                                /> */}
                                <span> {language === "En" ? "Ar" : "En"}</span>
                            </p>
                        )}

                        <Header data={data} />
                        {/* <div className="header">
            <h1>Visitors Registration</h1>
            <p>Tired of long queues?</p>
            <p>
              Be smart, save time, and get ahead of the crowds by registering
              now!
            </p>
          </div> */}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* <p className="instruction">
              Fields marked with an <span>*</span> are required
            </p> */}
                            <div className="texts">
                                <label>
                                    <p>
                                        First Name \ الأسم الاول <span>*</span>
                                    </p>
                                    <input
                                        name="name"
                                        type="text"
                                        style={{
                                            borderColor: error.name && "red",
                                            paddingRight: error.name && "60px",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        required
                                    />
                                    <p
                                        className={
                                            error.name ? "fname" : "error"
                                        }
                                    >
                                        {language === "En"
                                            ? "This is a required field."
                                            : "هذا الحقل مطلوب"}
                                    </p>
                                    <div
                                        className={
                                            error.name ? "fname" : "error_box"
                                        }
                                    >
                                        <i className="fas fa-exclamation"></i>
                                    </div>
                                </label>
                                <label>
                                    <p>
                                        Last Name \ الأسم العائله <span>*</span>
                                    </p>
                                    <input
                                        name="lname"
                                        type="text"
                                        style={{
                                            borderColor: error.lname && "red",
                                            paddingRight: error.lname && "60px",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        required
                                    />
                                    <p
                                        className={
                                            error.lname ? "lname" : "error"
                                        }
                                    >
                                        {language === "En"
                                            ? "This is a required field."
                                            : "هذا الحقل مطلوب"}
                                    </p>
                                    <div
                                        className={
                                            error.lname ? "lname" : "error_box"
                                        }
                                    >
                                        <i className="fas fa-exclamation"></i>
                                    </div>
                                </label>
                                <label>
                                    <p>
                                        Email \ البريد الالكتروني <span>*</span>
                                    </p>
                                    <input
                                        name="email"
                                        type="email"
                                        style={{
                                            borderColor: error.email && "red",
                                            paddingRight: error.email && "60px",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        required
                                    />
                                    <p
                                        className={
                                            error.email ? "email" : "error"
                                        }
                                    >
                                        {language === "En"
                                            ? "This is a required field."
                                            : "هذا الحقل مطلوب"}
                                    </p>
                                    <div
                                        className={
                                            error.email ? "email" : "error_box"
                                        }
                                    >
                                        <i className="fas fa-exclamation"></i>
                                    </div>
                                </label>

                                <label>
                                    <p>
                                        Mobile \ رقم الهاتف <span>*</span>
                                    </p>
                                    <input
                                        name="mobileno"
                                        type="tel"
                                        style={{
                                            borderColor:
                                                error.mobileno && "red",
                                            paddingRight:
                                                error.mobileno && "60px",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        required
                                    />
                                    <p
                                        className={
                                            error.mobileno ? "mobile" : "error"
                                        }
                                    >
                                        {language === "En"
                                            ? "This is a required field."
                                            : "هذا الحقل مطلوب"}
                                    </p>
                                    <div
                                        className={
                                            error.mobileno
                                                ? "mobile"
                                                : "error_box"
                                        }
                                    >
                                        <i className="fas fa-exclamation"></i>
                                    </div>
                                </label>
                                <DynamicQuestions
                                    questions={questions}
                                    recordTicketsTypes={recordTicketsTypes}
                                    handleChanger={handleChanger}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleRadiobuttonChange={
                                        handleRadiobuttonChange
                                    }
                                    handleBlur={handleBlur}
                                    handleFocus={handleFocus}
                                    handleInputFile={handleInputFile}
                                    handleSelector={handleSelector}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                            <Radiobutton
                                questions={questions}
                                handleRadiobuttonChange={
                                    handleRadiobuttonChange
                                }
                            />
                            <Checkboxes
                                isChecked={isChecked}
                                handleCheckboxChange={handleCheckboxChange}
                                questions={questions}
                            />
                            {/* <ReCAPTCHA
              className="recaptcha"
              sitekey={SITE_KEY}
              ref={captchaRef}
            /> */}

                            {!isSubmitting && (
                                <button>
                                    {language === "En" ? "Submit " : "تسجيل"}
                                </button>
                            )}
                            {isSubmitting && (
                                <button
                                    type="button"
                                    className="disabled"
                                    disabled
                                >
                                    {language === "En"
                                        ? "Submitting"
                                        : " جاري التسجيل"}
                                </button>
                            )}
                            <p className="link">
                                {language === "En"
                                    ? "Registration By"
                                    : " التسجيل عن طريق"}{" "}
                                <a href="https://www.frontiers.events/">
                                    Frontiers
                                </a>
                            </p>
                            <p
                                className="error"
                                style={{
                                    display:
                                        isError || checkBoxValidation
                                            ? "none"
                                            : "block",
                                }}
                            >
                                {language === "En"
                                    ? "Please correct errors before submitting this form."
                                    : " يرجى تصحيح الاخطاء قبل تقديم هذا النموذج."}
                            </p>
                            <p
                                className="error"
                                style={{
                                    display: !checkBoxValidation
                                        ? "none"
                                        : "block",
                                }}
                            >
                                {language === "En"
                                    ? "Please correct checkboxes errors before submitting this form."
                                    : " يرجى تصحيح اخطاء ال checkboxes قبل تقديم هذا النموذج."}
                            </p>
                        </form>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        backgroundColor: "black",
                    }}
                >
                    <div class="lds-dual-ring"></div>
                </div>
            )}
        </>
    );
};

export default Form;
