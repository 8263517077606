import { ToastContainer } from "react-toastify";
import "./App.css";
import Form from "./component/Form";
import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <div className="">
            <Form />
            <ToastContainer />
        </div>
    );
}

export default App;
