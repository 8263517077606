import { ToastContainer } from "react-toastify";
import "./App.css";
import Form from "./component/Form";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/:id" element={<Form />} />
        <Route
          path="*"
          element={
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                height: "100vh",
                backgroundColor: "black",
              }}
            >
              Page not found
            </p>
          }
        />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
